<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import { delAction, getAction, postAction, putAction } from '@/command/netTool'
import { Input, Select } from 'ant-design-vue'
import DrawerFormModal from '@/components/DrawerFormModal'

export default {
  name: 'scenic-spot-guide-public-facilities',
  data() {
    return {
      ...api.command.getState(),
      language_type: [],
      showModal: false,
      formDetail: {
        scenicGuideNameList: [],
      },
      areaCode: [],
    }
  },
  mounted() {
    this.getList()

    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'areaCode',
    })

    getAction('/admin/dict/codes?codes=language_type', {}, '/bpi').then((e) => {
      this.language_type = (e.data['language_type'] || []).map((e) => ({
        name: e.name,
        value: e.id,
      }))
    })
  },
  methods: {
    getList() {
      api.command.getList.call(this, {
        url: '/farmScenicGuideDetails/page',
        current: 1,
        params: {
          scenicGuideId: this.$route.query.id,
        },
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
        },
        {
          dataIndex: 'mapIcon',
          title: '图片',
          align: 'left',
          customRender: (text) => {
            return <img src={text} style={{ width: '30px', height: '30px' }} />
          },
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
        },
        {
          dataIndex: 'status',
          title: '位置',
          type: 'badge',
          customRender: (text, records) => {
            return `经度${records.lon} 纬度${records.lat}`
          },
        },
        {
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  getAction(`/farmScenicGuideDetails/detail?id=${records.id}`).then((e) => {
                    this.showModal = true
                    this.formDetail = e.data
                    this.formDetail.dw = e.data.lat + ',' + e.data.lon

                    this.formDetail.districtCode = [e.data.provinceCode, e.data.cityCode, e.data.districtCode]
                  })
                },
              },
              {
                name: '删除',
                display: true,
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  delAction(`/farmScenicGuideDetails?id=${records.id}`).then((e) => {
                    this.$message.success('删除成功')
                    this.getList()
                  })
                },
              },
            ]
          },
        },
      ]
    },
    getFormData() {
      return [
        {
          form: [
            {
              name: '名称(15字符内)',
              type: 'input',
              key: 'name',
              maxLength: 15,
            },
            {
              name: '弹窗预览图',
              type: 'upload',
              key: 'bannerUrl',
            },
            {
              name: '地图图标',
              type: 'upload',
              key: 'mapIcon',
            },
            {
              name: '经纬度',
              type: 'latlng',
              key: 'dw',
              onChange: (val) => {
                getAction('/api/gao', {
                  location: `${val.lng},${val.lat}`,
                }).then((res) => {
                  let { address, codes } = res.data
                  this.formDetail.districtCode = codes
                  this.formDetail.address = address
                  this.$forceUpdate()
                })
              },
            },
            {
              name: '地址',
              type: 'row',
              cols: 24,
              gutter: 5,
              children: [
                {
                  type: 'cascader',
                  cols: 10,
                  key: 'districtCode',
                  label: '地址',
                  typeData: this.areaCode,
                  placeholder: '请选择地址',
                },
                {
                  type: 'input',
                  cols: 14,
                  key: 'address',
                  label: '地址',
                },
              ],
            },
            {
              name: '手绘地图经纬度',
              type: 'latlngGroup',
              keys: ['mapIconLat', 'mapIconLon'],
            },
            {
              name: 'VR链接',
              type: 'input',
              key: 'vrUrl',
              cols: 24,
              maxLength: 400,
              props: {
                maxLength: 400,
              },
            },
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: this.formDetail.scenicGuideNameList,
              showPagination: false,
              tableType: 'editDelete',
              showFootButton: true,
              columns: [
                {
                  title: '语言类型',
                  align: 'center',
                  dataIndex: 'nameType',
                  customRender: (text, records, index) => {
                    return (
                      <Select
                        value={text}
                        onChange={(e) => {
                          this.formDetail.scenicGuideNameList[index]['nameType'] = e
                          this.$forceUpdate()
                        }}
                      >
                        {this.language_type.map((e) => {
                          return <Select.Option value={e.value}>{e.name}</Select.Option>
                        })}
                      </Select>
                    )
                  },
                },
                {
                  title: '内容',
                  align: 'center',
                  dataIndex: 'name',
                  customRender: (text, records) => {
                    return (
                      <Input
                        value={text}
                        onInput={(e) => {
                          records.name = e.target.value
                        }}
                      />
                    )
                  },
                },
                {
                  dataIndex: 'a8',
                  title: '操作',
                  type: 'buttonGroup',
                  typeData: ({ records }) => {
                    return [
                      {
                        name: '删除',
                        display: true,
                        type: 'pop',
                        popTitle: '确认是否删除吗?',
                        onClick: () => {},
                      },
                    ]
                  },
                },
              ],
            },
          ],
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          onClick: () => {
            this.showModal = true
            this.formDetail = {
              scenicGuideNameList: [],
            }
          },
        },
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: (data) => {
        //         let idList = data.map((e) => e.id)
        //         return api.command.delPostArr.call(this, {
        //           url: '/audit/farmAuditFeedback/deleteBatch',
        //           params: { idList },
        //         })
        //       },
        //     },
        //   ],
        // },
      ]
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'district']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.formDetail.districtCode[i]
      })
      return obj
    },
    onSubmit(data) {
      const { id } = this.$route.query
      let codeData = []
      if (this.formDetail.districtCode && this.formDetail.districtCode.length) {
        codeData = this.getSumbitData()
      }
      if (!data.mapIconLat && !data.mapIconLon) {
        data.mapIconLat = data.lat
        data.mapIconLon = data.lon
      }
      if (data.id) {
        putAction(
          '/farmScenicGuideDetails/update',
          {
            scenicGuideId: id,
            ...data,
            ...codeData,
          },
          '/operation'
        ).then(() => {
          this.$message.success('编辑成功')
          this.getList()
          this.showModal = false
        })
      } else {
        postAction('/farmScenicGuideDetails/save', {
          scenicGuideId: id,
          ...data,
          ...codeData,
        }).then(() => {
          this.$message.success('添加成功')
          this.getList()
          this.showModal = false
        })
      }
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
          params={{
            scenicGuideId: this.$route.query.id,
          }}
        />
        <DrawerFormModal
          visible={this.showModal}
          title="基本信息"
          width={'800px'}
          onClose={() => (this.showModal = false)}
          onSubmit={this.onSubmit}
          data={this.getFormData()}
          form={this.formDetail}
        ></DrawerFormModal>
      </div>
    )
  },
}
</script>
